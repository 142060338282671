import React from 'react'
import Logo3615 from '../../assets/img/3615_logo.png'
import VideoTeaser from '../../assets/img/EXPORT 169_1-LOW.mp4'
import PhoneImg from '../../assets/img/telephone.png'
import Audio1 from '../../assets/img/voice1.mp3'
import Audio2 from '../../assets/img/voice2.mp3'
import Audio3 from '../../assets/img/voice4.mp3'
import Audio4 from '../../assets/img/voice5.mp3'
import './index.scss'

const BlockIntro = () => {

    const [wobble, setWobble] = React.useState(0)

    let audio1 = new Audio(Audio1)
    let audio2 = new Audio(Audio2)
    let audio3 = new Audio(Audio3)
    let audio4 = new Audio(Audio4)


    const getRandomElementFromArray = (arrName) => {
        return arrName[Math.floor(Math.random()*arrName.length)]
    }

    const start = () => {
        getRandomElementFromArray([audio1, audio2, audio3, audio4]).play()
    }

    return (
        <section className='blockIntro'>
            <div className="container">
            <img className='logo' src={Logo3615} alt="" />
            <img
                wobble={wobble}
                onAnimationEnd={() => {
                    setWobble(0)
                }} onClick={() => {
                    start()
                    setWobble(1)
                }} src={PhoneImg} alt='' className='phone' />
            {/* <div className='image'></div> */}
            <video muted={true} loop={true} autoPlay={true} playsInline={true} src={VideoTeaser} className="image" type="video/mp4"></video>
            <p className='citation'>Tu en as marre de la tristesse ? Appelle le 3615 Bonheur !</p>
            {/* <p className='presentation'><span>Soprano</span>, PDG de 3615 Bonheur</p> */}
            <a className='decouvrir' href="#decouvrir">Découvrir</a>
            </div>

        </section>
    )
}

export default BlockIntro
