import React from 'react'
import BlockIntro from '../../components/BlockIntro'

const Home = () => {
    return (
        <main className='home'>
            <BlockIntro />
        </main>
    )
}

export default Home