import React from 'react'
import './index.scss'
import OrangeBlockImg from '../../assets/img/entreprise-desktop.png'

const OrangeBlock = () => {
    return (
        <section className='orangeBlock' id="decouvrir">
            <div className='container'>
                <picture>
                <img className='image' src={OrangeBlockImg} alt="" />
                </picture>
                <p>Tu en as marre des factures élevées ? Du prix de l’essence élevé ? Des arnaques en tout genre ?</p>
                <a className='contactBtn' href='#contact'>Contacte-nous</a>
            </div>
        </section>
    )
}

export default OrangeBlock