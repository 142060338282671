import logo from './logo.svg';
import './App.scss';
import Home from './pages/Home';
import OrangeBlock from './components/OrangeBlock';
import JoinUs from './components/JoinUs';
import Footer from './components/Footer';
import Tiktok from './components/Tiktok';

function App() {
  return (
    <div className="App">
      <Home />
      <OrangeBlock />
      <JoinUs />
      <Tiktok />
      <Footer />
    </div>
  );
}

export default App;
