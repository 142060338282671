import React, { useEffect, useState } from 'react'
import CoverImg from '../../assets/img/cover.jpg'
import './index.scss'

const Formulaire = () => {
    const [isDone, setIsDone] = useState(false)

    useEffect(() => {
        var connect_button = new window.WMGConnect(".wmg-button", {
            // Would you like a popup "Thank you" message to automatically appear once the user has been acquired?
            // opt_in_thank_you_enabled: true,
            opt_in_thank_you_enabled: false,
            opt_in_form_target: '#formulaire'
            // Data sources are automatically generated based on how the campaign is set up but you can specify a custom one below
            // exacttarget_datasource: 'WMG_EdSheeran_PreSave'
          });
          
          connect_button.setCallback(function(connect_data){
            // Callback code
            window._satellite.track("email sign-up")
            setIsDone(true)
          });
    }, [])

    return (
        <section id="contact" className='formulaireContainer'>
            {!isDone && <section id="formulaire"></section>}
            {isDone && (
                <div className='formEnd'>
                    <p>Nouveau single inédit <b>3615 Bonheur</b> le 21 octobre</p>
                    <p>Enregistre le titre dès à présent dans ta bibliothèque.</p>
                    <a href="https://soprano.lnk.to/presave3615"><img src={CoverImg} alt="" /></a>
                    <a className='enregistrer' href="https://soprano.lnk.to/presave3615">Enregistrer</a>
                </div>
            )}
        </section>
    )
}

export default Formulaire