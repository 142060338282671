import React from 'react'
import TiktokSoprano from '../../assets/img/tiktok_sopra.png'
import LogoTiktok from '../../assets/img/tiktok_icon.png'
import './index.scss'

const Tiktok = () => {
    return (
        <section className='tiktok'>
            <h2>Nous sommes aussi sur<br />Tiktok</h2>
            <a rel="noreferrer" target="_blank" href="https://www.tiktok.com/@soprano/video/7155423230101064966">Suivez-nous</a>
            <img className='tiktokSoprano' src={TiktokSoprano} alt="" />

            <img className='logoTiktok' src={LogoTiktok} alt="" />
            <img className='logoTiktok' src={LogoTiktok} alt="" />
            <img className='logoTiktok' src={LogoTiktok} alt="" />
            <img className='logoTiktok' src={LogoTiktok} alt="" />
        </section>
    )
}

export default Tiktok