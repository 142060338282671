import React from 'react'
import StarImg from '../../assets/img/star.png'
// import PP from '../../assets/img/avisPP.png'
import './index.scss'

const Avis = ({ pp, text, prenom }) => {
    return (
        <div className='avis'>
            <div className='textAndStars'>
                <p>{ text }</p>
                <p className='pseudo'>{ prenom }</p>
                <img className='stars' src={StarImg} alt="" />
            </div>
            <img className='pp' src={pp} alt="" />
        </div>
    )
}

export default Avis