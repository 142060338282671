import React from 'react'
import SopranoJoin from '../../assets/img/form.png'
import Avis from '../Avis'
import Formulaire from '../Formulaire'
import PP1 from '../../assets/img/avis1-2.jpg'
import PP2 from '../../assets/img/avis2.jpg'
import PP3 from '../../assets/img/avis3.png'
import PP4 from '../../assets/img/avis4.png'
import './index.scss'

const JoinUs = () => {
    return (
        <section className='joinUs'>
            <div className='joinUsIntro'>
                <div className='introTexts'>
                    <h2>Rejoins-nous</h2>
                    <p>Et tente de gagner des cadeaux pour être heureux…</p>
                </div>

                <img src={SopranoJoin} alt="" />
            </div>
            <Formulaire />

            <h2 className='confiance'>Ils nous ont fait
                    <span>confiance</span>
                </h2>
            <div className='avisContainer'>
                <Avis prenom="Anthony" pp={PP1} text="Service de qualité, un pur bonheur !" />
                <Avis prenom="Anne-So" pp={PP2} text="Totalement satisfaite ! Charmant personnel" />
                <Avis prenom="Mathieu" pp={PP3} text="Depuis que j’ai appelé le 36 15 Bonheur ma vie a changé" />
                <Avis prenom="Nadège" pp={PP4} text="Tout allait déjà bien mais tout va mieux ^^" />
            </div>
        </section>
    )
}

export default JoinUs
